<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import Layout from '@/components/common/Layout.vue';
import LinkButton from '@/components/common/LinkButton.vue';
import routesConstants from '@/constants/routes.constants';

const SubscriptionCanceled = defineComponent({
  components: {
    Layout,
    LinkButton,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      // eslint-disable-next-line global-require
      subscriptionCanceledImage: require('../../assets/images/subscription_canceled.svg'),
    };
  },
  render() {
    return (
      <Layout>
        <div class={this.$style.container}>
          <h1 class={[this.$style.title, 'mb-2']}>{this.t('pages.subscriptionCanceled.title')}</h1>

          <div class={this.$style.illustrationWrapper}>
            <img class={this.$style.illustration} src={this.subscriptionCanceledImage} alt="" />
          </div>

          <p class="mt-2 tac" vHtml={this.t('pages.subscriptionCanceled.description')} />

          <LinkButton to={routesConstants.OFFERS} class="mt-4 tac">
            {this.t('pages.subscriptionCanceled.goBackToOffersButton')}
          </LinkButton>
        </div>

      </Layout>
    );
  },
});

export default SubscriptionCanceled;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.container {
  padding: 2rem;
  max-width: 120rem;
  margin: 0 auto;

  padding-bottom: 8rem;

  @include xs {
    padding: 2rem 4rem;
  }
}

.title {
  text-align: center;
  font-size: 2.4rem;

  @include ipad {
    font-size: 3rem;
  }
}

.illustrationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.illustration {
  margin-top: 2rem;
  max-width: 40rem;
  width: 100%;
  height: auto;

  padding: 2rem;
}
</style>
